import React from 'react';
import { Link } from 'react-router-dom';
import './About.css';
import Profile from './work-in-progress.png';
import NumberedCircle from './NumberedCircle';

const About = () => {
  return (
    <div className="about">
        <div className="header-container">
            <h1>Om Oss</h1>
        </div>
        <div className='about-main'>
            <div className="about-main-item">
                <div className="about-main-item-child">
                    <img aria-hidden="true" src={Profile} alt="Profile picture" />
                    <div className="person-title">
                        <span>Person 1</span>
                        <span>Titel</span>
                    </div>
                </div>
                <div className="about-main-item-child">
                    <img aria-hidden="true" src={Profile} alt="Profile picture" />
                    <div className="person-title">
                        <span>Person 2</span>
                        <span>Titel</span>
                    </div>
                </div>
            </div>
            <div className="about-main-item">
                <span>AEX WEB DESIGNS</span>
                <h2>Vi Ger Småföretag Behandlingen Som Storföretag Får</h2>
                <p>Det vi upptäckte var att många småföretag inte har råd att lägga ut tusentals kronor i förskott på en ny hemsida. Och de som hade det blev utnyttjade och har en fruktansvärd hemsida som ser ut som om den var byggd av någon i en fängelsehåla och som inte sett designtrender på de senaste 10 åren. De var i en frustrerande situation - de hade antingen inte råd med en bra hemsida, och om de hade det, visste de inte vem de kunde lita på för att skapa något fantastiskt. Så vi justerade vår affärsmodell för att passa deras behov. Det är så vi kom på vår modell med 0 kronor i förskott och 1500 kronor i månaden. Det är mer hanterbart för ett småföretag att hantera och kommer inte att skada deras bankkonton.</p>
            </div>
        </div>
        <div className="about-quality">
            <h2><span className="blue-text">Kvalitet</span> <span className="dark-text">Över Kvantitet</span></h2>
            <p>Vi fokuserar på behoven hos varje enskilt företag och anpassar innehållet efter vad som gör det unikt. Vår samarbetsprocess är mycket praktisk eftersom vi arbetar nära tillsammans med dig för att skapa en webbplats som du är 100% nöjd med och inte något som du bara nöjer dig med.</p>
        </div>
        <div className="about-perks">
            <div className="about-perks-item">
                <NumberedCircle number={1} />
                <h3>Inget Förskrivet Innehåll</h3>
                <p>Vi skriver unikt och engagerande innehåll för ditt företag. Alla är unika!</p>
            </div>
            <div className="about-perks-item">
                <NumberedCircle number={2} />
                <h3>Oslagbar Kundservice</h3>
                <p>Inga automatiserade system - När du behöver hjälp svarar huvudutvecklaren på ditt samtal.</p>
            </div>
            <div className="about-perks-item">
                <NumberedCircle number={3} />
                <h3>Inga Begränsningar På Design</h3>
                <p>Vi kan anpassa designen för att passa dina önskemål. Allt är anpassningsbart!</p>
            </div>
        </div>
        <div className='about-link-div'>
            <Link to="/tjanster" className="about-link">VÅRA TJÄNSTER</Link>
        </div>
    </div>
  );
};

export default About;
