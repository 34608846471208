import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import Swoosh from './footer-swoosh.svg'
// import logo from './logo_temp.png';
import logo from './aex-removebg.png';

const Footer = () => {

  return (
    <footer className="footer">
      <div className="swoosh-container">
        <img aria-hidden="true" src={Swoosh} alt="Footer background" />
      </div>
      <div className="footer-content">
        <div className="footer-flex">
          <div className="footer-item">
            <img aria-hidden="true" src={logo} alt="Logo footer" />
            <p>kontakt@aexwebdesigns.se</p>
            <p>(+46) TBD</p>
          </div>
          <div className="footer-item">
            <h3>Navigation</h3>
            <Link to="/">Hem</Link>
            <Link to="/om-oss">Om oss</Link>
            <Link to="/tjanster">Tjänster</Link>
            <Link to="/portfolj">Portfölj</Link>
            <Link to="/blogg">Blogg</Link>
            <Link to="/kontakt">Kontakt</Link>
          </div>
          <div className="footer-item">
            <h3>Tjänster</h3>
            <p>Webbdesign</p>
            <p>Utveckling</p>
            <p>Underhåll</p>
            <p>SEO-tjänster</p>
            <p>Innehållsskapande</p>
            <p>Logotyper</p>
          </div>
        </div>
        <div className="footer-bottom">
          Copyright © 2024 | AEX Web Designs | All Rights Reserved
        </div>
      </div>
    </footer>
  );
};

export default Footer;
