import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
// import logo from './logo_temp.png';
import logo from './aex-removebg.png';
import './Navbar.css';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    // Scroll to top whenever the location changes
    window.scrollTo(0, 0);
  }, [location]);

  const isActive = (path) => {
    return location.pathname === path;
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <nav className="navbar">
      <Link to="/" className="logo-link" onClick={closeMenu}>
        <img src={logo} alt="Company Logo" />
      </Link>
      <div
        className={`hamburger ${menuOpen ? 'open' : 'closed'}`}
        onClick={() => setMenuOpen(!menuOpen)}
      >
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className={`links ${menuOpen ? 'open' : ''}`}>
        <Link to="/" className={isActive('/') ? 'active' : ''} onClick={closeMenu}>Hem</Link>
        <Link to="/om-oss" className={isActive('/om-oss') ? 'active' : ''} onClick={closeMenu}>Om oss</Link>
        <Link to="/tjanster" className={isActive('/tjanster') ? 'active' : ''} onClick={closeMenu}>Tjänster</Link>
        <HashLink to="/#portfolio" onClick={closeMenu}>Portfölj</HashLink>
        <Link to="/blogg" className={isActive('/blogg') ? 'active' : ''} onClick={closeMenu}>Blogg</Link>
        <Link to="/kontakt" className={isActive('/kontakt') ? 'active' : ''} onClick={closeMenu}>Kontakt</Link>
      </div>
    </nav>
  );
};

export default Navbar;
