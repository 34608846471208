import React from 'react';

const NumberedCircle = ({ number }) => {
  const circleStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '150px', // Outermost circle size
    width: '150px',
    borderRadius: '50%',
    backgroundColor: 'rgba(67, 183, 255, 0.3)', // Dark blue
    position: 'relative',
    fontSize: '50px', // Adjust font size as needed
    color: 'white', // Number color
  };

  const midCircleStyle = {
    height: '120px',
    width: '120px',
    borderRadius: '50%',
    backgroundColor: 'rgba(67, 183, 255, 0.6)', // Medium blue
    position: 'absolute',
  };

  const innerCircleStyle = {
    height: '90px',
    width: '90px',
    borderRadius: '50%',
    backgroundColor: 'rgba(67, 183, 255, 1)', // Light blue
    position: 'absolute',
  };

  const numberStyle = {
    color: 'white',
    fontSize: '50px',
    position: 'absolute',
    zIndex: 10,
  };

  return (
    <div style={circleStyle}>
      <div style={midCircleStyle}></div>
      <div style={innerCircleStyle}></div>
      <div style={numberStyle}>{number}</div>
    </div>
  );
};

export default NumberedCircle;
