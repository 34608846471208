import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import Navbar from './Navbar';
import Footer from './Footer';
import Home from './Home';
import About from './About';
import Services from './Services';

// AppContent component to use useLocation
function AppContent() {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  // Add the 'home' class to the div if it's the homepage
  const appClassName = `App${isHomePage ? ' home' : ''}`;

  return (
    <div className={appClassName}>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/om-oss" element={<About />} />
        <Route path="/tjanster" element={<Services />} />
      </Routes>
      <Footer />
    </div>
  );
}

// App component
function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
